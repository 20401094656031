.banner-card,
.banner-card-container,
.banner-card-container > .card-header {
  width: 100%;
  height: 100%;
}

.vertical-position {
  flex-direction: column;
}

.horizontal-position {
  flex-direction: row;
}

.banner-card-head {
  height: 10%;
  display: flex;
}

.banner-card-body {
  height: 90%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-evenly;
}

.title-vertical-position {
  font-size: 70px;
  text-align: center;
}

.title-horizontal-position {
  width: 50%;
  font-size: 35px;
  text-align: start;
  word-break: break-all;
}

.banner-value {
  font-size: 48px;
}

.font-700 {
  font-weight: 700;
  line-height: 100%;
  font-style: normal;
}
