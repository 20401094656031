@import "../src/components/molecules/banner-card/banner-card.styles.css";
@import "../src/components/atoms/spinner/spinner.styles.css";

.unity-banner b {
  text-align: center;
  padding: 10px;
}

.covered-total span {
  text-align: center;
}

body {
  margin: 0;
}
.icon {
  width: 40px;
  height: 40px;
  margin-top: 4px;
  margin-left: 20px;
}

.unidade {
  width: 200px;
}

.white {
  color: white;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.column {
  display: flex;
  flex-direction: column;
}

.fill-h {
  height: 100% !important;
  box-sizing: border-box;
}

.fill-w {
  width: 100% !important;
  box-sizing: border-box;
}

.flex-1 {
  flex: 1;
}

.section {
  margin: 5px;
}
.flex-center {
  display: flex;
  align-items: center;
}

.eq-w {
  flex: 1;
}

.scroll {
  overflow-y: scroll;
}

.main {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.body {
  flex: 1;
}

.fg-1 {
  flex-grow: 1;
  flex-shrink: 0;
}

.text-center {
  text-align: center;
}

.text-white {
  color: white;
  padding: 8px;
}

.text-neutro {
  color: #75779a;
  padding: 8px;
}

.f-grow {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
}

.fill-available-h {
  flex: 1 1 0;
  overflow: hidden; /* https://stackoverflow.com/questions/36247140/why-dont-flex-items-shrink-past-content-size */
}

.fill-available-w {
  flex: 1 1 0;
  min-width: 0;
}

.w-40 {
  width: 40%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.h-40 {
  height: 40%;
  box-sizing: border-box;
}

.h-50 {
  height: 50%;
  box-sizing: border-box;
}

.h-60 {
  height: 60%;
  box-sizing: border-box;
}

.fill-w,
.hg-fill {
  width: 100%;
}

.puff-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.default-spinner {
  width: 100%;
  height: 90%;
  display: flex;
  position: inherit;
  align-items: center;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}

/* Cards */

body {
  background-color: #141437;
}

/* flex column sb */

.flex-column-sb {
  display: flex;
  padding: 5px;
  flex-direction: column;
  justify-content: space-between;
}

.flex-column-sb > .floors-table .table-row {
  padding: 0 !important;
}

.flex-column-sb .status {
  height: 15px;
  width: 15px;
  left: 0px;
  top: 15.5px;
  border-radius: 10px;
}

.flex-column-sb .item {
  width: 190px !important;
}

.flex-column-sb .section {
  margin: 0 !important;
}

.flex-column-sb .containerWrap {
  margin: 10px;
  overflow: hidden;
}

.flex-column-sb .text-hover {
  position: absolute !important;
}

.flex-column-sb .statusText,
.flex-column-sb .status-p-text {
  width: 0 !important;
}

.flex-column-sb .section {
  cursor: pointer;
}

.light-bg {
  background: #1f1f43;
}

.solid-bg {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.transparent-bg {
  background-color: transparent;
}

.donut-value {
  margin: 0;
  font-size: 35px;
  font-weight: 700;
}

.donut-title > .text-neutro {
  margin: 0;
  font-size: 24px;
  font-weight: 400;
}

.swal-btn {
  width: 150px;
}

@media screen and (max-width: 1440px) {
  .donut-title > .text-neutro {
    font-size: 18px;
  }

  .donut-value {
    font-size: 20px;
  }
}
