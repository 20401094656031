.monitoring-spinner {
  width: 0;
  height: 0;
}

.map-spinner {
  overflow: hidden;
}

.hide {
  display: none;
}
